<template>
	<div>
		<b-card>
			<b-form>
				<b-row>
					<b-col cols="12">
						<!-- <b-row class="justify-content-center align-items-center vh-100">
							<b-col>
							</b-col>
						</b-row>-->
						<label class="mb-2">
							<strong>NINE BOX</strong>
						</label>

						<div class="matrix-container">
							<div class="productivity-label">PRODUCTIVIDAD</div>

							<div class="indicators-vertical">
								<div
									v-for="(indicator, index) in indicators"
									:key="'vertical-' + index"
									class="indicator-vertical"
								>{{ indicator }}</div>
							</div>

							<div class="matrix">
								<div
									v-for="(cell, index) in matrixData"
									:key="index"
									:style="{ 'color': cell.textColor, 'background-color': cell.backgroundColor }"
									class="cell"
								>
									<div class="corner-number">{{ cell.number }}</div>
									<!-- <div class="content">{{ cell.names.join("\n") }}</div> -->
									<div
										class="content"
										v-html="formatNames(cell.providers)"
									></div>
								</div>
							</div>

							<div class="indicators-horizontal">
								<div
									v-for="(indicator, index) in indicators"
									:key="'horizontal-' + index"
									class="indicator-horizontal"
								>{{ indicator }}</div>
							</div>
						</div>

						<div class="security-label">SEGURIDAD</div>

						<div class="performance-bar">
							<div
								v-for="(section, index) in sections"
								:key="index"
								class="performance-section"
								:style="{ backgroundColor: section.backgroundColor, color: section.textColor }"
							>
								<div class="section-title">{{ section.title }}</div>
								<div class="section-percentage">{{ section.percentage }}</div>
							</div>
						</div>
					</b-col>

					<b-col cols="12 mb-2 mt-2">
						<label for="security">
							<strong>Variación de Resultados</strong>
						</label>
						<b-table
							ref="refVariationTable"
							:fields="variationsTable.fields"
							:items="variationsTable.items"
							bordered
							responsive
							show-empty
							empty-text="No se encontraron registros coincidentes"
						>
							<template #table-busy>
								<div class="text-center text-primary my-2">
									<b-spinner class="align-middle mb-1"></b-spinner>
									<div>
										<b>CARGANDO</b>
									</div>
								</div>
							</template>

							<template #cell(observation)="data">
								<div>
									<b-button
										variant="outline-primary"
										class="btn-icon rounded-circle"
										@click="openObservationModal(data.item, data.index)"
									>
										<feather-icon icon="MessageSquareIcon" />
									</b-button>
								</div>
							</template>
						</b-table>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="colpa">
								<strong>Colpa (comentario general)</strong>
							</label>

							<b-form-textarea
								id="colpa"
								v-model="documentData.colpa"
								placeholder="Ingrese texto aquí"
								rows="3"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="rotation">
								<strong>Rotación (comentario general)</strong>
							</label>

							<b-form-textarea
								id="rotation"
								v-model="documentData.rotation"
								placeholder="Ingrese texto aquí"
								rows="3"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="conclusion">
								<strong>Conclusiones y Recomendaciones</strong>
							</label>

							<!-- <b-form-textarea
								id="conclusion"
								v-model="documentData.conclusion"
								placeholder="Ingrese texto aquí"
								rows="8"
							/>-->

							<quill-editor
								id="conclusion"
								v-model="documentData.conclusion"
								:options="snowOption"
								placeholder="Ingrese texto aquí"
							/>
						</b-form-group>
					</b-col>

					<b-col
						cols="12"
						class="my-1"
					>
						<b-alert
							variant="info"
							show
						>
							<div class="alert-body small">
								<feather-icon
									icon="InfoIcon"
									class="mr-50"
								/>
								<a
									class="text-info"
									href="/plantillas/plantilla_anexo.xlsx"
								>
									Descargar
									<strong>plantilla para anexos</strong>
								</a>
							</div>
						</b-alert>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="security_appendix">
								<strong>ANEXO: Ocurrencias de Seguridad</strong>
							</label>

							<b-form-textarea
								id="security_appendix"
								v-model="documentData.security_appendix"
								placeholder="Ingrese texto aquí"
								rows="2"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Cuadro general de evaluación de desempeño (imagen)</strong>

								<b-link
									v-if="documentData.file_screenshot_url"
									class="ml-1"
									:href="documentData.file_screenshot_url"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>
							<b-form-file
								v-model="documentData.file_screenshot"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".jpg, .png, .jpeg"
							/>
						</b-form-group>
					</b-col>

					<b-col
						cols="12 mb-2"
						v-if="documentData.isMINA"
					>
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Detalle de Improductivos UP MN (plantilla)</strong>
								<b-link
									v-if="documentData.file_template_url"
									class="ml-1"
									:href="documentData.file_template_url"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>

							<b-form-file
								v-model="documentData.file_template"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".xlsx"
							/>
						</b-form-group>
					</b-col>

					<b-col
						cols="12 mb-2"
						v-if="documentData.isMINA"
					>
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Detalle de Improductivos UP SM (plantilla)</strong>
								<b-link
									v-if="documentData.file_template_two_url"
									class="ml-1"
									:href="documentData.file_template_two_url"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>

							<b-form-file
								v-model="documentData.file_template_two"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".xlsx"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-card>

		<b-col cols="12 mt-2">
			<b-button-loading
				text="GENERAR REPORTE PDF"
				variant="outline-primary"
				@process-action="generateDocument"
				:processing="processing"
				:block="true"
				size="lg"
			/>
		</b-col>

		<b-modal
			ref="refObservationModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			title="OBSERVACIONES"
			cancel-title="Cerrar"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			size="lg"
			centered
		>
			<b-row>
				<b-col
					cols="12"
					class="mb-2"
				>
					<b-form-group>
						<label
							for="security"
							class="text-primary"
						>
							<strong>Observación</strong>
						</label>
						<b-form-textarea
							id="security"
							v-model="observationModal"
							placeholder="Deje observación aquí ..."
							rows="4"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<template #modal-footer="{ cancel }">
				<b-button
					@click="saveObservation"
					variant="primary"
				>GUARDAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import documentStoreModule from "../documentStoreModule"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import VueSweetalert2 from "vue-sweetalert2"
import { quillEditor } from "vue-quill-editor"
/* import "quill/dist/quill.core.css" */
import "quill/dist/quill.snow.css"
/* import "quill/dist/quill.bubble.css" */
import store from "@/store"
import Vue from "vue"
import "animate.css"
import router from "@/router"
import axios from "@axios"

Vue.use(VueSweetalert2)

export default {
	components: {
		quillEditor,
		BButtonLoading
	},
	directives: {
		Ripple
	},
	setup() {
		const { swalNotification, toastNotification } = useNotifications()

		const refObservationModal = ref(null)
		const observationModal = ref(null)
		const variationId = ref(null)
		const variationIndex = ref(null)
		const processing = ref(false)
		const matrixData = ref([])
		const sections = ref([])
		const indicators = ["BAJO", "MEDIO", "ALTO"]
		const refVariationTable = ref(null)
		const snowOption = ref({
			theme: "snow"
		})
		const variationsTable = ref({
			fields: [],
			items: []
		})

		const documentData = ref({
			colpa: null,
			rotation: null,
			conclusion: null,
			security_appendix: null,
			file_screenshot: null,
			file_template: null,
			file_template_two: null,
			file_screenshot_url: null,
			file_template_url: null,
			file_template_two_url: null,
			isMINA: true
		})

		const DOCUMENT_APP_STORE_MODULE_NAME = "app-document"

		// REGISTER MODULE
		if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
			store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
				store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-document/fetchDocument", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				const { document } = response.data

				documentData.value = {
					colpa: document.colpa,
					rotation: document.rotation,
					conclusion: document.conclusion,
					security_appendix: document.security_appendix,
					file_screenshot: null,
					file_template: null,
					file_template_two: null,
					file_screenshot_url: document.file_screenshot_url || null,
					file_template_url: document.file_template_url || null,
					file_template_two_url: document.file_template_two_url || null,
					isMINA: document.isMINA
				}
				matrixData.value = [...document.matrixData]
				sections.value = [...document.sections]

				variationsTable.value.fields = document.table.fields
				variationsTable.value.items = document.table.items
			})
			.catch((error) => {
				router.push({ name: "documents-list" })
				toastNotification(
					error.response?.data?.message ||
						"Ocurrió un error al obtener los datos",
					false
				)
			})

		const openObservationModal = ({ id, observation }, index) => {
			variationId.value = id
			variationIndex.value = index
			observationModal.value = observation

			refObservationModal.value.show()
		}

		const saveObservation = async () => {
			try {
				const response = await axios.put(`/variations/${variationId.value}`, {
					observation: observationModal.value
				})

				variationsTable.value.items[variationIndex.value].observation =
					observationModal.value

				toastNotification(response.data.message)
				refObservationModal.value.hide()
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const generateDocument = () => {
			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de generar el reporte?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, generar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (!result.value) {
					processing.value = false
					return
				}

				const { file_screenshot, file_template, file_template_two, ...data } =
					documentData.value

				const payload = {
					file_screenshot,
					file_template,
					file_template_two,
					data
				}

				store
					.dispatch("app-document/generateDocument", {
						id: router.currentRoute.params.id,
						payload
					})
					.then((response) => {
						router.push({ name: "documents-list" })
						swalNotification(response.data.message)
					})
					.catch((error) => {
						const message =
							error.response.data.message || "Error al generar el reporte"
						swalNotification(message, false)
					})
					.finally(() => {
						processing.value = false
					})
			})
		}

		const formatNames = (names) => {
			return names.join("<br>")
		}

		return {
			documentData,
			processing,
			snowOption,

			refObservationModal,
			refVariationTable,
			variationsTable,
			observationModal,

			openObservationModal,
			saveObservation,
			generateDocument,

			matrixData,
			sections,
			indicators,
			formatNames
		}
	}
}
</script>

<style scoped>
.matrix-container {
	display: grid;
	grid-template-areas:
		"productivity-label indicators-vertical matrix"
		"empty empty indicators-horizontal";
	grid-template-columns: 80px 40px auto 120px; /* 80px para "PRODUCTIVIDAD", 40px para los indicadores verticales */
	grid-template-rows: auto 30px; /* Altura automática para matriz, 30px para indicadores horizontales */
	justify-content: center; /* Centra horizontalmente */
	align-items: center; /* Centra verticalmente */
	/* height: 100vh; */ /* Ocupa toda la altura de la ventana */
}

.productivity-label {
	grid-area: productivity-label;
	display: flex;
	justify-content: center; /* Centra horizontalmente dentro del contenedor */
	align-items: center; /* Centra verticalmente dentro del contenedor */
	transform: rotate(-90deg); /* Rota el texto para que sea vertical */
	font-weight: bold;
	font-size: 20px;
	white-space: nowrap; /* Evita que el texto se divida */
}

.indicators-vertical {
	grid-area: indicators-vertical;
	display: grid;
	grid-template-rows: repeat(
		3,
		1fr
	); /* Divide el contenedor en 3 filas iguales */
	justify-items: center; /* Centra los textos horizontalmente en cada fila */
	align-items: center; /* Centra los textos verticalmente en cada fila */
	width: 40px; /* Ancho del contenedor para los indicadores rotados */
	height: 600px; /* Altura igual a la de la matriz */
}

.indicator-vertical {
	font-weight: bold;
	text-align: center;
	transform: rotate(-90deg); /* Rota el texto 90 grados hacia la izquierda */
	transform-origin: center; /* Centra el punto de rotación del texto */
	white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.matrix {
	grid-area: matrix;
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Tres columnas */
	grid-template-rows: repeat(3, 1fr); /* Tres filas */
	width: 600px; /* Ajustar al tamaño deseado */
	height: 600px;
	border: 1px solid black;
}

.cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid black;
}

.corner-number {
	position: absolute;
	top: 5px;
	right: 10px;
	font-weight: bold;
	font-size: 11px;
}

.content {
	text-align: center;
	font-size: 10px;
	font-weight: bold;
	white-space: pre-line;
	padding: 10px;
}

.indicators-horizontal {
	grid-area: indicators-horizontal;
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Tres columnas para cada indicador */
	width: 600px; /* Igual al ancho de la matriz */
	text-align: center; /* Centrar el texto dentro de cada columna */
	justify-items: center; /* Centra el texto horizontalmente */
	align-items: center; /* Centra el texto verticalmente */
}

.indicator-horizontal {
	font-weight: bold;
	text-align: center;
}

.security-label {
	margin-top: 30px;
	font-weight: bold;
	font-size: 20px;
	text-align: center; /* Centrar el texto dentro de cada columna */
	justify-items: center; /* Centra el texto horizontalmente */
	align-items: center; /* Centra el texto verticalmente */
}

.performance-bar {
	display: flex;
	width: 420px; /* Ancho total del cuadro */
	margin: 30px auto 20px auto; /* Centra el cuadro horizontalmente */
	border: 1px solid #000; /* Borde opcional */
}

.performance-section {
	flex: 1; /* Asegura que todos los cuadrantes tengan el mismo ancho */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px; /* Espaciado interno */
	border-right: 1px solid #000; /* Divide las secciones */
}

.performance-section:last-child {
	border-right: none; /* Elimina el borde del último elemento */
}

.section-title {
	font-weight: bold;
	font-size: 12px;
	text-align: center;
}

.section-percentage {
	font-size: 11px;
	font-weight: bold;
	text-align: center;
}
</style>

<style>
.table-custom-red {
	background-color: #ff0000 !important;
	color: #fff !important;
}

.table-custom-yellow {
	background-color: #ffff00 !important;
	color: #000 !important;
}

.table-custom-green {
	background-color: #92d050 !important;
	color: #000 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>